:root {
  --lf-green: #c0d62e;
  --lf-light-green: #ecf2c0;
  --light-blue: #f2f8f9;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* --------------- DASHBOARD --------------- */
.dashboard-card {
  background-color: #fff;
  padding: 32px 24px;
  margin: 20px;
  min-width: 15rem !important;
}

.dashboard-card:hover {
  transition: all 0.2s ease-out;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  transform: translateY(-4px) scale(1.005) translateZ(0);
  background-color: var(--light-blue) !important;
  cursor: pointer;
}

.dashboard-card img {
  max-width: 156px;
  max-height: 156px;
}

@media only screen and (max-width: 500px) {
  .card-deck.dashboard-card {
    margin: 0;
  }

  .dashboard-card {
    padding: 0.75rem;
    margin: 0.25rem;
    min-width: 8rem !important;
  }
  
  .dashboard-card img {
    max-width: 78px;
    max-height: 78px;
  }

  .dashboard-card-body {
    font-size: smaller;
    text-align: center;
    padding: 1rem 0 0 0 !important;
  }

  .dashboard-card-title {
    font-size: small !important;
  }

  .dashboard-card-text {
    display: none;
  }
}


/* --------------- LOGIN & REGISTER --------------- */
#login-card {
  min-width: 500px !important;
}

#login-card-body {
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  padding: 3rem;
}

#login-card-body:hover {
  transition: none;
  transform: none;
  background-color: #fff !important;
  cursor: auto;
}

#login-card-body h2 {
  margin-bottom: 2rem;
}

#login-card-body .form-group {
  margin-bottom: 1.5rem;
}

#login-card-body input {
  background-color: var(--light-blue);
}

@media only screen and (max-width: 500px) {
  #login-card {
    min-width: auto !important;
  }
  
  #login-card-body {
    padding: 2rem 3rem 2rem 3rem !important;
    margin-top: 1rem !important;
  }
}

/* --------------- SETTINGS --------------- */
.settings-card {
  background-color: #fff;
  padding: 32px 24px;
  margin: 20px;
  min-width: 15rem !important;
}

.settings-card:hover {
  transition: all 0.2s ease-out;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  transform: translateY(-4px) scale(1.005) translateZ(0);
  background-color: var(--light-blue) !important;
  cursor: pointer;
}

.settings-card img {
  max-width: 156px;
  max-height: 156px;
}

@media only screen and (max-width: 500px) {
  .card-deck.settings-card {
    margin: 0;
  }

  .settings-card {
    padding: 0.75rem;
    margin: 0.25rem;
    min-width: 8rem !important;
  }
  
  .settings-card img {
    max-width: 78px;
    max-height: 78px;
  }

  .settings-card-body {
    font-size: smaller;
    text-align: center;
    padding: 1rem 0 0 0 !important;
  }

  .settings-card-title {
    font-size: small !important;
  }

  .settings-card-text {
    display: none;
  }
}


/* --------------- NAVBAR --------------- */
.welcome-message {
  color: rgba(255, 255, 255, 0.75) !important;
}

#logout-button {
  border-color: var(--lf-green);
  color: var(--lf-green);
  font-weight: bold;
}

#logout-button:hover {
  background-color: var(--lf-green);
  color: black;
}

.navbar-dark .navbar-nav .nav-item.active .nav-link {
  color: white;
}

/* --------------- GENERAL PAGE --------------- */
.page {
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 10% !important;
  margin-right: 10% !important;
}

.hidden {
  display: none;
}

.center-page {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

/* --------------- DRAG AND DROP --------------- */
#drag-overlay {
  border: dashed #59a9ff 2px;
  border-radius: 2px;
  background-color: rgba(89,169,255,.8);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

#drag-overlay-text {
  position: absolute;
  top: 45%;
  right: 0;
  left: 0;
  text-align: center;
  color: white;
  font-size: 20px;
}

.drag-target {
  height: 200px;
  background-color: #fafafa; 
  border: 2px dashed #eeeeee;
  border-radius: 2px;
  color: #bdbdbd;
}

.drag-target-text {
  position: absolute;
  top: 40%;
  right: 0;
  left: 0;
  text-align: center;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-userSelect: none;
}

#clickable-area {
  cursor: pointer;
  height: 100%;
}

/* --------------- UPLOADING FILES --------------- */
.alert {
  overflow-wrap: break-word;
}

.uploaded-file-stage { 
  flex: 0 0 100%;
  margin-top: 1.25rem;
  min-height: 100px;
}

.uploaded-file-header {
  display: flex;
}

.uploaded-file-info {
  margin: auto;
  margin-left: 1rem;
}

.uploaded-file-info:hover {
  cursor: pointer;
}

.uploaded-file-buttons {
  flex: 0 0 100%;
}

.uploaded-file-results {
  flex: 0 0 100%;
  margin-top: 2rem;
}

.no-result-text {
  font-size: 1.25rem;
}

/* --------------- ACCORDIONS ------------------- */
.result-table-card-header:hover {
  cursor: pointer;
}

@media print {
  .accordion .card .collapse:not(.show) {
    display: block;
  }
}

/* --------------- GENERAL TABLES --------------- */
tbody tr th {
  font-weight: normal;
}

.table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
  background-color: var(--lf-light-green);
}

.table-dark tbody tr:hover td, .table-dark tbody tr:hover th {
  background-color: transparent !important;
}

.table-total {
  background-color: rgb(136, 220, 234) !important;
  text-align: right;
}

button > div > svg {
  pointer-events: none;
}

@media print { 
  .table th,
  .table td {
    background-color: transparent !important;
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: transparent;
  }

  .table-total {
    background-color: rgb(196, 196, 196) !important;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    text-align: right;
  }
}

/* --------------- UPDATE TABLES --------------- */
.pagination {
  margin: 0;
}

.dropdown-menu {
  top: 145% !important;
}

.dropdown-menu li a {
  display: block;
  text-decoration: none;
  color: black;
}

.react-bootstrap-table table {
  table-layout: auto;
}

/* --------------- MODAL --------------- */
.form-check.form-check-inline input[type=radio] {
  transform: translateX(1rem) scale(1.25);
  z-index: 2;
}

.form-check.form-check-inline input[type=radio]:hover {
  cursor: pointer;
}

.form-check.form-check-inline input[type=radio]:checked + label {
  background-color: rgba(0, 123, 255, 0.40) !important;
}

.form-check.form-check-inline input[type=radio]:hover + label {
  background-color: rgba(0, 123, 255, 0.10);
}

.form-check.form-check-inline {
  margin-bottom: 0.5rem;
}

.form-check.form-check-inline label {
  display: inline-block;
  border: 1px solid grey;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem 0.5rem 2rem;
  transform: translateX(-1rem) translateY(-0.1rem);
  z-index: 1;
}

.form-check.form-check-inline label:hover {
  cursor: pointer !important;
}

.col-form-label {
  font-weight: bold;
}



.form-check.form-check-inline input[type=checkbox] {
  transform: translateX(1rem) scale(1.15);
  z-index: 2;
}

.form-check.form-check-inline input[type=checkbox]:hover {
  cursor: pointer;
}

.form-check.form-check-inline input[type=checkbox]:checked + label {
  background-color: rgba(0, 123, 255, 0.40) !important;
}

.form-check.form-check-inline input[type=checkbox]:hover + label {
  background-color: rgba(0, 123, 255, 0.10);
}

/* --------------- LOADING --------------- */
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.loading {
  -webkit-animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}